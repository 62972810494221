import React, { Suspense } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import '../styles/Landing.css';
import { Helmet } from 'react-helmet';

// Lazy load the components
const WhyChooseUs = React.lazy(() => import("./WhyChooseUs"));
const SocialMedia = React.lazy(() => import("./Social"));

// Lazy load images
const LazyImage = ({ src, alt, style, className }) => {
    return <img src={src} alt={alt} loading="lazy" style={style} className={className} />;
};

export default function LandingPage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate =useNavigate();
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "1200px",
        margin: "0"
    };

    const containerStyleSmallDevices = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "98vw",
        width: "100%",
        overflowX: "hidden",
        margin: "0",
        padding: "0"
    };

    const containerStyleLargeDevices = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        width: "50vw"
    };

    const trymemesKenya = () => {
        navigate("/try")
    }

    return (
        <>
            <div>
                <div style={containerStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'black', position: 'sticky', alignItems: 'center', zIndex: 1000 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <Typography sx={{ fontSize: '30px', color: 'white' }}>Memes</Typography>
                            <Typography sx={{ fontSize: '30px', color: '#45ADE4' }}>Kenya</Typography>
                        </Box>
                    </Box>

                    {/* Separate p tags or replace with div/span */}
                    <div><span>"Humor is a universal language."</span></div>

                    <div id="welcomeText">
                        <Helmet>
                            {/* Meta Tags for SEO */}
                            <title>Kenyan Memes | Home to a ton of Kenyan Humor</title>
                            <meta name="description" content="Explore the largest collection of Kenyan memes, Kenyan humor, and Kenyan satire. Stay updated with the latest Kenyan memes that capture the essence of Kenyan culture. With great collections from whatsapp Memes , Twitter/ x memes , facebook kenya memes to some of the crazy memes out here. Let the memes begin!" />
                            <meta name="keywords" content="Kenyan memes, Kenya humor, kenyan satire, funny memes, current kenya meme events, hot kenyan memes , whatsapp kenyan memes , whatsapp kenya memes , funny memes, funny kenya memes, memes , swahili memes , kenya meme , kenyan memes 254 ,latest memes today ,latest kenyan memes ,whatsapp funniest kenyan memes, trending memes kenya today , latest kenyan memes and jokes , memes funny kenya " />
                            <meta name="robots" content="index, follow" />
                            
                            {/* Open Graph Meta Tags */}
                            <meta property="og:title" content="Kenyan Memes | Home to a ton of Kenyan Humor" />
                            <meta property="og:description" content="Explore the largest collection of Kenyan memes, humor, and satire." />
                            <meta property="og:image" content="https://www.memeskenya.com/CWmzEjpWxD/6d2f6263-65f8-4485-8243-175d76e95f2d-Screenshot_2024-06-14-12-13-09-69_0b2fce7a16bf2b728d6ffa28c8d60efb.jpg" />
                            <meta property="og:url" content="https://www.memeskenya.com" />
                            <meta property="og:type" content="website" />

                            {/* Twitter Card Meta Tags */}
                            <meta name="twitter:card" content="https://www.memeskenya.com/CWmzEjpWxD/6d2f6263-65f8-4485-8243-175d76e95f2d-Screenshot_2024-06-14-12-13-09-69_0b2fce7a16bf2b728d6ffa28c8d60efb.jpg" />
                            <meta name="twitter:title" content="Kenyan Memes | Home to a ton of Kenyan Humor" />
                            <meta name="twitter:description" content="Explore the largest collection of Kenyan memes, humor, and satire." />
                            <meta name="twitter:image" content="https://www.memeskenya.com/CWmzEjpWxD/5f6ee791-ec8e-4ed1-b74a-bccde73d51b7-Screenshot_2024-08-05-11-46-08-64_0b2fce7a16bf2b728d6ffa28c8d60efb.jpg" />

                            {/* Canonical Link */}
                            <link rel="canonical" href="https://www.memeskenya.com" />
                        </Helmet>

                        {/* Ensure no <p> inside another <p> */}
                        <section id="welcomeText">
                            <h1>Welcome to the Home of Kenyan Memes</h1>
                            <p>
                                Through Kenyan memes (mostly Kenya memes, we promise, except for Science and Technology, Business, and other universal humorous issues), we share the common Kenyan experiences, humorous takes, and satirical perspectives on the current events happening in the country or affecting the country. Let's do this. But wait, if we have to post universal memes, doesn't that make it a universal meme platform? Sawa basi! Bora wakenya wa-relate. Kaende!! Kaende sana!!
                            </p>
                        </section>
                    </div>

                    <div style={{ display: "flex", justifyContent: "right", marginBottom: "0", alignItems: "center" }}>
                        <p style={{ color: "whitesmoke", textAlign: "center", justifyContent: "center", fontSize: "25px", marginRight: "1%" }}>Admin</p>
                        <Avatar
                            alt="memeskenya admin dp"
                            src={require('../visuals/mk.webp')}
                            sx={{
                                width: "60px",
                                height: "60px"
                            }}>
                        </Avatar>
                    </div>
                    <br />
                    <div style={containerStyle}>
                        {/* <DelayedLink to="/try" delay={500} style={{ textDecoration: "none", color: "aliceblue" }}> */}
                            <button id="tryButton" onClick={trymemesKenya}>Try Out MemesKenya</button>
                        {/* </DelayedLink> */}
                        <br />
                        <Link to="/log-in" style={{ textDecoration: "none", color: "aliceblue" }}>
                            <button id="signInButton">Be a MemesKenyan</button>
                        </Link>
                    </div>

                    <div style={containerStyle}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <WhyChooseUs />
                        </Suspense>
                    </div>

                    <div style={isSmallScreen ? containerStyleSmallDevices : containerStyleLargeDevices}>
                        <p id="textTitle" style={{ marginBottom: isSmallScreen ? "10%" : "5%", fontSize: '25px', width: isSmallScreen ? '300px' : '420px' }}>
                            Access Memes<span style={{ color: "#3C97C7", fontSize: "25px" }}>Kenya</span> across your Devices.
                        </p>
                        <div>
                            <div style={{ justifyContent: "center", alignItems: 'center', display: 'flex' }}>
                                <LazyImage src={require('../visuals/phone.png')} className="mockup" style={{ width: "150px", height: "300px" }} />
                                <LazyImage src={require('../visuals/laptop.png')} className="mockup" style={{ marginLeft: "1%", width: "300px", height: "180px" }} />
                            </div>
                        </div>
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <SocialMedia />
                    </Suspense>
                </div>
            </div>
        </>
    );
}
